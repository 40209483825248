import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Div100vh from "react-div-100vh"
import Layout from "../components/layout"
import SEO from "../components/seo"
import * as styles from "../css/cl.module.css"

const IndexPage = () => (
  <Layout back>
    <SEO title="CL THANK YOU" />
    <Div100vh>
      <div className={styles.container}>
        <div className={styles.wrap}>
          <iframe
            title="CL Thank You Video"
            className={styles.video}
            src="https://www.youtube.com/embed/dv8nbp96eWk"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
          <StaticImage
            src="../img/text/cl-thank-you.png"
            draggable={false}
            alt="Caption for video reading CL Thank You Video"
          />
        </div>
      </div>
    </Div100vh>
  </Layout>
)

export default IndexPage
